.signin {


	&::before,
	&::after {
		top: 0;
		height: 100vh;
	}

	a {
		color: get-color(primary, 1);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
